/* Fix sweeet alert typografy & backdrop */
.swal2-popup{
    font-family: 'Roboto' !important;
    font-style: normal !important;
    font-weight: 300 !important;
    src: url(https://fonts.gstatic.com/s/roboto/v27/KFOlCnqEu92Fr1MmSU5fCRc4EsA.woff2) format('woff2');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

body.swal2-height-auto {
    height: inherit !important;
}

.swal2-container{
    z-index: 5000 !important;
}

.swal2-container.swal2-backdrop-show, .swal2-container.swal2-noanimation {
    background: rgba(0,0,0,.7) !important;
}

/* Fix modal title */
.MuiDialogTitle-root h2{
    font-size: 20px;
    font-weight: bold;
}